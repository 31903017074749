@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --body-bg: hsl(33, 100%, 88%);
  --btn-width: 8rem;
  --btn-height: 2.5rem;
  --btn-bg: #ffbf58;
  --btn-battom-bg: #ffbf587d;
  --btn-shadow-bg: #ffbf583f;
  --text-cl: #efefef;
  --border-radius: 10px 10px 15px 15px;
}

body {
  background-color: #000000;
  color: #ffffff;

}


/* width */
::-webkit-scrollbar {
  width: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-track {
  width: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffbe58;
  border-radius: 4px;
}

.fc-1 {
  color: #ffbe58;
}

/* / ============ DESKTOP-VIEW ============ / */

@media all and (min-width: 992px) {

  /* =============================NAVBAR-SECTION================================= */

  #navsection .nav-logo-css {
    width: 100px;
    height: 50px;
  }

  .navbarColor-scroll {
    background-color: #000000 !important;
    transition-timing-function: ease-in-out;
    height: 70px;
    box-shadow: #3a3a3a5e 0px 25px 20px -20px;
  }

  .navbarColor-default {
    background-color: transparent !important;
  }

  #navsection .navbar {
    background-color: transparent;
    position: fixed;
    z-index: 9999;
    width: 100vw;
  }

  #navsection .nav-text-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    /* letter-spacing: 2px; */

  }

  #navsection .nav-text-css:hover {
    color: #ffbe58;

  }

  #navsection .nav-item {
    margin-right: 0.5em;
  }


  /* =============================TECHNOLOGIES-SECTION================================= */
  .tech-title-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    text-transform: capitalize;
    margin-top: 20px;
  }

  .pre-nxt-icon-css {
    font-size: 40px;
    color: #ffbe58;
  }

  #tech_section .nav-pills .nav-link.active,
  #tech_section .nav-pills .show>.nav-link {
    background: #25252586;
    box-shadow: 0 4px 30px #1a1a1a19;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }

  .tech-card-animated {
    transition: transform 0.3s ease-in-out;
    transform: scale(1.2);
  }

  .tech-card-css {
    background-color: transparent;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: #25252586;
    box-shadow: 0 4px 30px #1a1a1a19;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    padding-top: 2em;
  }

  .tech-row-height-css {
    height: 500px;
  }

  .tabs-title-alignment-css {
    position: relative;

    bottom: 15vh;
  }

  .tech-title-postion {
    position: relative;
    top: 10vh;
  }

  .tech-icon-styling-css {
    font-size: 50px;
    color: #ffbe58;
    width: 50px;
    height: 50px;
  }

  .tech-img-styling-css {
    width: 50px;
    height: 50px;

  }

  .fc-Javascript {
    color: #efd81d;
  }


  /* =============================FOOTER-SECTION========================================= */
  .footer-container {
    height: 400px;

    /* background-color: rgb(0, 0, 0);
    background: radial-gradient(#ffbf5866, #000000, #000000); */
    /* background-image: radial-gradient(ellipse at 75% 15%, rgb(14, 102, 150) 0%, transparent 50%); */
    background-size: 100% 100%;
  }

  .footer-logo-sizing {
    width: 300px;
    height: 150px;
  }

  .footer-small-heading-size-css {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    /* letter-spacing: 4px;
    word-spacing: 1px; */
  }

  .footer-small-heading-size-css:hover {
    color: #ffffff;
    cursor: pointer;
  }

  .footer-icon-size-css {
    font-size: 30px;
    cursor: pointer;
  }

  /* =============================ABOUT-US-SECTION========================================= */


  .developer-card-img {
    width: 100%;
    height: 100%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

  }

  /* .aboutus-section {

    background-image: linear-gradient(to bottom, #121212, #ffbe58, #ffbe58);


  } */


  /* =============================SECTION-3================================= */
  .industries-flex-alignment {
    position: relative;
    top: 10%;
  }

  .industries-bg-styling {
    background-image: linear-gradient(to right, #000000d6, #000000a7, #000000d3), url("./images/aboutus.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

  }

  /* .updown-height-css {
    height: 600px;
  } */

  .dark-opacity {
    background-color: #000000;
    padding: 0;
  }

  .section-3-card-image-center-css {
    width: 100%;
    height: 250px;
    opacity: 0.5;
  }


  .section-3-card-image-center-css:hover {
    cursor: pointer;
    width: 100%;
    height: 250px;
    opacity: 1;
  }

  .section-3-card-opacity-css {
    width: 100%;
    height: 250px;
  }

  .section-3-card-position-css {
    position: relative;
    top: 30%;

  }

  .wrapper {
    background-color: #1a1a1a;
    padding: 1em;
    position: relative;
    width: 100%;
    height: 250px;
  }

  .white-card {
    display: block;
    text-decoration: none;
    position: absolute;
    background-color: #1a1a1a;
    left: 10px;
    top: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .2);

  }

  .orange-border {
    background-color: #000000;
    background-image: linear-gradient(130deg, #f88f325b, #f9bb4f97);
    position: absolute;
  }

  .orange-top {
    width: 100px;
    height: 10px;
    top: 0;
    left: 0;
  }

  .orange-left {
    width: 10px;
    height: 100px;
    top: 0;
    left: 0;
  }

  @keyframes leftgrow {
    0% {
      height: 100px;
      width: 10px;
    }

    40% {
      height: 100%;
      width: 10px;
    }

    100% {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes leftshrink {
    0% {
      width: 100%;
      height: 100%;
    }

    60% {
      height: 100%;
      width: 10px;
    }

    100% {
      height: 100px;
      width: 10px;
    }
  }

  @keyframes topgrow {
    0% {
      width: 100px;
      height: 10px;
    }

    60% {
      width: 100%;
      height: 10px;
    }

    100% {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes topshrink {
    0% {
      width: 100%;
      height: 100%;
    }

    40% {
      width: 100%;
      height: 10px;
    }

    100% {
      width: 100px;
      height: 10px;
    }
  }

  .orange-left.grow {
    animation: leftgrow .5s forwards;
  }

  .orange-left.shrink {
    animation: leftshrink .5s forwards;
  }

  .orange-top.grow {
    animation: topgrow .5s forwards;
  }

  .orange-top.shrink {
    animation: topshrink .5s forwards;
  }


  .section-3-title-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 27px;
    text-transform: capitalize;
    margin-top: 20px;
  }


  /* =================================SECTION-2========================================== */
  .services-bg-styling {
    background-image: linear-gradient(to right, #000000b3, #000000a7, #00000000), url("./images/girl-lap-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

  }

  .card-container-columns>.col:nth-child(odd) {
    align-self: start;
  }

  .card-container-columns>.col:nth-child(even) {
    align-self: end;
  }


  .flip-button-css {
    border: none;
    color: #000000;
    background-color: var(--btn-bg);
    padding: 0.7em 0.7em;
    border-radius: 5px;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
  }

  .flip-button-css:hover {
    border: none;
    color: #000000;
    background-color: var(--btn-bg);
    padding: 0.7em 0.7em;
    border-radius: 5px;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    outline: none;
    box-shadow: none;
  }

  .card-icon-styling-css {
    font-size: 70px;
    color: #ffbe58;
  }

  .card-inner-para-css {
    color: rgb(181, 181, 181);
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    text-align: center !important;
    /* word-spacing: 1px; */
  }

  .card-inner-title-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
  }


  .flip-card {
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 250px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

  }

  /* .flip-card-front {
    background-color: #fff;
    color: #000;
  } */

  .flip-card-back {
    /* background-color: #f0f0f0;
    color: #333; */
    transform: rotateY(180deg);

  }

  .flip-card-inner.flipped {
    transform: rotateY(180deg);
  }

  .zig-zag-card-css:hover {
    cursor: pointer;
    background-color: transparent;
    background-image: linear-gradient(180deg, #02010100 0%, #2a2a2a9d 100%);
    transform: translateY(-8px);
  }

  .zig-zag-card-css {
    transition: all .5s ease-in-out;
    color: #ffffff;
    padding: 1em 1em 1em 1em;
    border-radius: 1.9em 1.9em 0 1.9em;
    background: #25252586;
    box-shadow: 0 4px 30px #1a1a1a19;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    /* background-color: #1a1a1a; */
  }


  .zig-zag-row-height {
    height: 650px;
  }

  .button-container {
    position: relative;
  }

  .button-container>.button {
    width: 130px;
    border: none;
    color: #000000;
    background-color: var(--btn-bg);
    padding: 0.75rem 1.5rem;
    border-radius: 15px;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    transform: translateY(0);
    -moz-transition: transform 250ms ease-in-out;
    -webkit-transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
    z-index: 0;
  }

  .button-container .button__bottom {
    width: 130px;
    height: var(--btn-height);
    background-color: var(--btn-battom-bg);
    border-radius: var(--border-radius);
    position: absolute;
    top: 12px;
    z-index: -1;
  }

  .button-container>.button__shadow {
    width: 130px;
    height: var(--btn-height);
    outline: 2px solid hsla(0, 62%, 67%, 0.15);
    background-color: var(--btn-shadow-bg);
    border-radius: var(--border-radius);
    position: absolute;
    top: 15px;
    left: -4px;
    z-index: -2;
  }

  .button-container>.button:active,
  .button-container>.button:focus-visible {
    transform: translateY(6px);
  }

  .inner-para-styling-css {
    color: rgb(181, 181, 181);
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
  }

  .border-bottom-css {
    border-bottom: 0.2em solid #ffbe58;
    width: 15%;
  }

  .border-bottom-css-2 {
    border-bottom: 0.2em solid #ffbe58;
    width: 5%;
  }

  .small-heading-size-css {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 4px;
    word-spacing: 1px;
  }

  .big-heading-size-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 45px;
  }

  /* =================================SECTION-1========================================== */

  #bannersectionHome {

    background-image: linear-gradient(to right, #0000005f, #00000065, #00000057), url("./images/banner-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    clip-path: url("#myCurve");

  }

  /* 
  .clippath-css-styling {
    overflow: hidden !important;
  }

  .clippath-css-styling-2 {
    clip-path: url("#myCurvetwo");
  } */

}

/* / ============ MOBILE-VIEW ============ / */

@media(max-width: 991px) {


  /* =============================NAVBAR-SECTION================================= */
  .navbar-nav {
    width: 100vw !important;
  }

  .navbar-toggler {
    background-color: #ffbf58;
    font-size: 1em;
  }

  .navbar-collapse {
    background-color: #000000;
    box-shadow: #ffbf58af 0px 25px 20px -20px;
  }

  #navsection .nav-logo-css {
    width: 80px;
    height: 40px;
  }

  .navbar-toggler-icon {
    width: 1em;
    height: 1em;

  }

  /* #navsection .navbar {
    background-color: #000000;
    position: fixed;
    z-index: 9999;
    width: 100vw;
    box-shadow: #3a3a3a85 0px 25px 20px -20px;

  } */

  .navbarColor-scroll {
    background-color: #000000 !important;
    transition-timing-function: ease-in-out;
    height: 70px;
    box-shadow: #3a3a3a5e 0px 25px 20px -20px;
  }

  .navbarColor-default {
    background-color: transparent !important;
  }

  #navsection .navbar {
    background-color: transparent;
    position: fixed;
    z-index: 9999;
    width: 100vw;
  }

  #navsection .nav-text-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;

    /* letter-spacing: 2px; */

  }

  #navsection .nav-text-css:hover {
    color: #ffbe58;

  }

  #navsection .nav-item {
    margin-right: 0.5em;
  }


  /* =============================TECHNOLOGIES-SECTION================================= */
  .tech-title-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    text-transform: capitalize;
    margin-top: 6px;
  }

  .pre-nxt-icon-css {
    font-size: 20px;
    color: #ffbe58;
  }

  #tech_section .nav-pills .nav-link.active,
  #tech_section .nav-pills .show>.nav-link {
    background: #25252586;
    box-shadow: 0 4px 30px #1a1a1a19;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }

  .tech-card-animated {
    transition: transform 0.3s ease-in-out;
    transform: scale(1.2);
  }

  .tech-card-css {
    background-color: transparent;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #25252586;
    box-shadow: 0 4px 30px #1a1a1a19;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    padding-top: 1em;
  }

  .tech-row-height-css {
    height: 300px;
  }

  .tabs-title-alignment-css {
    position: relative;

    bottom: 5vh;
  }

  /* .tech-title-postion {
    position: relative;
    top: 10vh;
  } */

  .tech-icon-styling-css {
    font-size: 25px;
    color: #ffbe58;
    width: 25px;
    height: 25px;
  }

  .tech-img-styling-css {
    width: 25px;
    height: 25px;

  }

  .fc-Javascript {
    color: #efd81d;
  }


  /* =============================FOOTER-SECTION========================================= */
  .footer-container {
    height: 200px;

    /* background-color: rgb(0, 0, 0);
      background: radial-gradient(#ffbf5866, #000000, #000000); */
    /* background-image: radial-gradient(ellipse at 75% 15%, rgb(14, 102, 150) 0%, transparent 50%); */
    background-size: 100% 100%;
  }

  .footer-logo-sizing {
    width: 150px;
    height: 70px;
  }

  .footer-small-heading-size-css {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .footer-small-heading-size-css:hover {
    color: #ffffff;
    cursor: pointer;
  }

  .footer-icon-size-css {
    font-size: 20px;
    cursor: pointer;
  }

  /* =============================ABOUT-US-SECTION========================================= */


  .developer-card-img {
    width: 100%;
    height: 100%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

  }

  /* .aboutus-section {
  
      background-image: linear-gradient(to bottom, #121212, #ffbe58, #ffbe58);
  
  
    } */


  /* =============================SECTION-3================================= */
  .industries-flex-alignment {
    position: relative;
    top: 10%;
  }

  .industries-bg-styling {
    background-image: linear-gradient(to right, #000000d6, #000000a7, #000000d3), url("./images/aboutus.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

  }

  /* 
  .updown-height-css {
    height: 140vh;
  } */

  .dark-opacity {
    background-color: #000000;
    padding: 0;
  }

  .section-3-card-image-center-css {
    width: 100%;
    height: 250px;
    opacity: 0.9;
    display: none;

  }


  .section-3-card-image-center-css:hover {
    cursor: pointer;
    width: 100%;
    height: 250px;
    opacity: 1;
    display: none;
  }

  .wrapper {
    background-color: #1a1a1a;
    padding: 1em;
    position: relative;
    width: 100%;
    height: 250px;
  }

  .white-card {
    display: block;
    text-decoration: none;
    position: absolute;
    background-color: #1a1a1a;
    left: 10px;
    top: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .2);

  }

  .orange-border {
    background-color: #000000;
    background-image: linear-gradient(130deg, #f88f325b, #f9bb4f97);
    position: absolute;
  }

  .orange-top {
    width: 100px;
    height: 10px;
    top: 0;
    left: 0;
  }

  .orange-left {
    width: 10px;
    height: 100px;
    top: 0;
    left: 0;
  }

  @keyframes leftgrow {
    0% {
      height: 100px;
      width: 10px;
    }

    40% {
      height: 100%;
      width: 10px;
    }

    100% {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes leftshrink {
    0% {
      width: 100%;
      height: 100%;
    }

    60% {
      height: 100%;
      width: 10px;
    }

    100% {
      height: 100px;
      width: 10px;
    }
  }

  @keyframes topgrow {
    0% {
      width: 100px;
      height: 10px;
    }

    60% {
      width: 100%;
      height: 10px;
    }

    100% {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes topshrink {
    0% {
      width: 100%;
      height: 100%;
    }

    40% {
      width: 100%;
      height: 10px;
    }

    100% {
      width: 100px;
      height: 10px;
    }
  }

  .orange-left.grow {
    animation: leftgrow .5s forwards;
  }

  .orange-left.shrink {
    animation: leftshrink .5s forwards;
  }

  .orange-top.grow {
    animation: topgrow .5s forwards;
  }

  .orange-top.shrink {
    animation: topshrink .5s forwards;
  }


  .section-3-title-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
    text-transform: capitalize;
    margin-top: 20px;
  }




  /* =================================SECTION-2========================================== */
  .services-bg-styling {
    background-image: linear-gradient(to right, #000000b3, #000000a7, #00000000), url("./images/girl-lap-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

  }


  .card-container-columns>.col:nth-child(odd) {
    align-self: start;
  }

  .card-container-columns>.col:nth-child(even) {
    align-self: end;
  }


  .flip-button-css {
    border: none;
    color: #000000;
    background-color: var(--btn-bg);
    padding: 0.7em 0.7em;
    border-radius: 5px;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
  }

  .flip-button-css:hover {
    border: none;
    color: #000000;
    background-color: var(--btn-bg);
    padding: 0.7em 0.7em;
    border-radius: 5px;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    outline: none;
    box-shadow: none;
  }

  .card-icon-styling-css {
    font-size: 70px;
    color: #ffbe58;
  }

  .card-inner-para-css {
    color: rgb(181, 181, 181);
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    text-align: center !important;

    /* word-spacing: 1px; */
  }

  .card-inner-title-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
  }


  .flip-card {
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 250px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  /* .flip-card-front {
      background-color: #fff;
      color: #000;
    } */

  .flip-card-back {
    /* background-color: #f0f0f0;
      color: #333; */
    transform: rotateY(180deg);
    top: 20%;
  }

  .flip-card-inner.flipped {
    transform: rotateY(180deg);
  }

  .zig-zag-card-css:hover {
    cursor: pointer;
    background-color: transparent;
    background-image: linear-gradient(180deg, #02010100 0%, #2a2a2a9d 100%);
    transform: translateY(-8px);
  }

  .zig-zag-card-css {
    transition: all .5s ease-in-out;
    color: #ffffff;
    padding: 1em 1em 1em 1em;
    border-radius: 1.9em 1.9em 0 1.9em;
    background: #25252586;
    box-shadow: 0 4px 30px #1a1a1a19;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    /* background-color: #1a1a1a; */
  }


  /* .zig-zag-row-height {
    height: 650px;
  } */

  .button-container {
    position: relative;
  }

  .button-container>.button {
    width: 130px;
    border: none;
    color: #000000;
    background-color: var(--btn-bg);
    padding: 0.75rem 1.5rem;
    border-radius: 15px;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    transform: translateY(0);
    -moz-transition: transform 250ms ease-in-out;
    -webkit-transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
    z-index: 0;
  }

  .button-container .button__bottom {
    width: 130px;
    height: var(--btn-height);
    background-color: var(--btn-battom-bg);
    border-radius: var(--border-radius);
    position: absolute;
    top: 12px;
    z-index: -1;
  }

  .button-container>.button__shadow {
    width: 130px;
    height: var(--btn-height);
    outline: 2px solid hsla(0, 62%, 67%, 0.15);
    background-color: var(--btn-shadow-bg);
    border-radius: var(--border-radius);
    position: absolute;
    top: 15px;
    left: -4px;
    z-index: -2;
  }

  .button-container>.button:active,
  .button-container>.button:focus-visible {
    transform: translateY(6px);
  }

  .inner-para-styling-css {
    color: rgb(181, 181, 181);
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
  }

  .border-bottom-css {
    border-bottom: 0.2em solid #ffbe58;
    width: 15%;
  }

  .border-bottom-css-2 {
    border-bottom: 0.2em solid #ffbe58;
    width: 5%;
  }

  .small-heading-size-css {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 4px;
    word-spacing: 1px;
  }

  .big-heading-size-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 34px;
  }

  /* =================================SECTION-1========================================== */

  #bannersectionHome {
    background-image: linear-gradient(to right, #0000005f, #00000065, #00000057), url("./images/mobile-banner-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    clip-path: url("#myCurvetwo");


  }

  /* .clippath-css-styling {
    overflow: hidden !important;
  } */

}

/*======================MEDIUM-SCREEN-RESPONSIVE=======================*/

@media (min-width: 991px) and (max-width: 1229px) {
  /* =============================TECHNOLOGIES-SECTION================================= */

  .tech-card-css {
    width: 150px;
    height: 150px;
    padding-top: 2em;
  }

  .tech-icon-styling-css {
    font-size: 40px;
    color: #ffbe58;
    width: 40px;
    height: 40px;
  }

  .tech-img-styling-css {
    width: 40px;
    height: 40px;
  }

  .tech-title-css {
    color: #ffffff;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    text-transform: capitalize;
    margin-top: 6px;
  }

  .industries-bg-styling {
    background-image: linear-gradient(to right, #000000d6, #000000a7, #000000d3), url("./images/aboutus.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

  }

  .industries-flex-alignment {
    position: relative;
    top: 5%;
  }

  /* =============================SECTION-3================================= */
  .section-3-card-image-center-css {
    width: 100%;
    height: 250px;
    opacity: 0.9;

  }

  .section-3-card-image-center-css:hover {
    width: 100%;
    height: 250px;
  }

  .section-3-card-position-css {
    position: relative;
    top: 10%;
  }

  .wrapper {
    padding: 1em;
    width: 100%;
    height: 250px;
  }

  .white-card {
    display: block;
    text-decoration: none;
    position: absolute;
    background-color: #1a1a1a;
    left: 10px;
    top: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .2);
  }

  .orange-top {
    width: 100px;
    height: 10px;
    top: 0;
    left: 0;
  }

  .orange-left {
    width: 10px;
    height: 100px;
    top: 0;
    left: 0;
  }

  @keyframes leftgrow {
    0% {
      height: 100px;
      width: 10px;
    }

    40% {
      height: 100%;
      width: 10px;
    }

    100% {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes leftshrink {
    0% {
      width: 100%;
      height: 100%;
    }

    60% {
      height: 100%;
      width: 10px;
    }

    100% {
      height: 100px;
      width: 10px;
    }
  }

  @keyframes topgrow {
    0% {
      width: 100px;
      height: 10px;
    }

    60% {
      width: 100%;
      height: 10px;
    }

    100% {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes topshrink {
    0% {
      width: 100%;
      height: 100%;
    }

    40% {
      width: 100%;
      height: 10px;
    }

    100% {
      width: 100px;
      height: 10px;
    }
  }

  .section-3-title-css {
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
  }


  .inner-para-styling-css {
    color: rgb(181, 181, 181);
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
  }


  .card-inner-para-css {
    color: rgb(181, 181, 181);
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    text-align: center !important;
    /* word-spacing: 1px; */
  }

  #bannersectionHome {
    background-image: linear-gradient(to right, #0000005f, #00000065, #00000057), url("./images/mobile-banner-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    clip-path: url("#myCurvetwo");

  }

}